body {
  background-color: rgb(243, 246, 249);
  padding-bottom: 20px;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader,
.MuiDataGrid-root .MuiDataGrid-cell {
  outline: none !important;
}

.MuiDataGrid-columnSeparator--sideRight {
  right: 0 !important;
}